.handle.active {
    border: 5px solid orange;
}

.example-track {
    border: 1px solid black;
    top: 40px;
}

.example-thumb {
    top: 10px;
    border-radius: 50%;
    width: 40px;
    text-align: center;
    border: 2px solid orange;
    cursor: pointer;
}

#pricemin, #pricemax {
    margin-top: 55px;
    display: inline-block;
    width: 100px;
    margin-right: 10px;
}