.slick-track {
    /* height: 150px; */
    display: flex !important;
}

.slick-slide {
 	height: inherit !important;
 	display: flex !important;
 	justify-content: center;
 	align-items: center;

}

.slick-slide > div {
    height: 100%;
}

.car_photo_carousel_img {
    max-height: 150px;
    max-width: 200px;
    /*overflow: hidden;*/
}

.car_photo_carousel_div {
    /*height: 550px;*/
    display: inline-block;
    float:none;
}
