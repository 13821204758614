/*=========================
	All css
==========================*/
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/fontawesome/css/font-awesome.min.css");
@import url("../plugins/themify/themify-icons.css");
@import url("../plugins/line-awesome/css/line-awesome.min.css");

/*=========================
	All css
==========================*/
@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700|Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|PT+Serif:400,400i,700,700i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto+Slab:100,300,400,700|Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Playfair+Display:400,400i,700,700i,900,900i|Signika:300,400,600,700');

/*
font-family: 'Hind', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
font-family: 'PT Serif', serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Lato', sans-serif;
font-family: 'Raleway', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Signika', sans-serif;
*/